import { RootState } from 'store';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  getFormsAndCatalogs,
  setFormsAndCatalogs,
} from 'overview/store/overviewActions';

import { ButtonPrimary } from 'shared/components/Button';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { nevada } from 'shared/config/Colors';
import { P } from 'shared/components/Typography';
import { Divider, Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import Loader from 'shared/components/Loader';
import RadioButtonWithDesc from 'shared/components/RadioButtonWithDesc';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import { FormCatalogTypeEnum } from 'overview/interface/FormAndCatalog';

const BrowseCatalogsContainer = styled.div``;

const HiddenLink = styled.a``;

const VerticalDivider = styled(Divider)`
  border: ${({ theme }) => `1px solid ${theme.mischka}`};
  height: 100%;
  margin: 0rem 2rem;
`;

interface CatalogsForm {
  catalogUrl: string;
}

const BrowseCatalogs = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();

  const [modalOpened, setModalOpened] = useState(false);

  const [catalogsLoading, setCatalogsLoading] = useState(true);

  const hiddenLinkRef = useRef<HTMLAnchorElement>(null);

  const catalogs = useSelector(
    (state: RootState) => state.overviewReducer.catalogs
  );

  const { register, handleSubmit, formState, reset, watch } =
    useForm<CatalogsForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        catalogUrl: '',
      },
    });

  const catalogUrlWatched = watch('catalogUrl');

  const onCatalogSelect = (data: CatalogsForm, close: () => void) => {
    if (hiddenLinkRef.current) {
      hiddenLinkRef.current.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    }

    reset();
    close();
  };

  useEffect(() => {
    if (modalOpened) {
      setCatalogsLoading(true);
      dispatch(getFormsAndCatalogs(setCatalogsLoading));
    } else {
      dispatch(setFormsAndCatalogs({ catalogs: null, forms: null }));
    }
  }, [modalOpened]);

  return (
    <BrowseCatalogsContainer>
      <HiddenLink
        ref={hiddenLinkRef}
        hidden
        href={catalogUrlWatched}
        target="_blank"
        rel="noopener"
      />
      <ModalWrapper
        modal
        lockScroll
        onOpen={() => setModalOpened((prevState) => !prevState)}
        onClose={() => setModalOpened((prevState) => !prevState)}
        trigger={<div>{children}</div>}
      >
        {(close: () => void) => (
          <Modal medium noHeader close={close}>
            <Wrapper flex wFull between>
              <Wrapper flex column wFull>
                <P bold fontSize={24}>
                  Catalogs
                </P>
                <P color={nevada}>Please select a catalog to view</P>

                <Spacer h="30px" />

                <ScrollbarsCustom
                  autoHide
                  autoHideDuration={300}
                  autoHideTimeout={500}
                  autoHeight
                  autoHeightMin={495}
                  autoHeightMax={495}
                >
                  {!catalogsLoading &&
                    catalogs
                      ?.filter(
                        (item) => item.type === FormCatalogTypeEnum.CATALOG
                      )
                      .map((catalog) => (
                        <RadioButtonWithDesc
                          {...register('catalogUrl', { required: true })}
                          data-flow-option
                          id={catalog.id}
                          key={catalog.id}
                          label={catalog.name}
                          value={catalog.fileUrl}
                        />
                      ))}

                  {!catalogsLoading && !catalogs && (
                    <EmptyState
                      marginTop={10}
                      marginBottom={30}
                      title="There are no catalogs"
                    />
                  )}

                  {catalogsLoading && <Loader size={42} />}
                </ScrollbarsCustom>

                <Spacer h="30px" />
              </Wrapper>
              <Wrapper flex column>
                <VerticalDivider />
              </Wrapper>
              <Wrapper flex column wFull>
                <P bold fontSize={24}>
                  Pricing Info
                </P>
                <P color={nevada}>Please select a topic to view</P>

                <Spacer h="30px" />

                <ScrollbarsCustom
                  autoHide
                  autoHideDuration={300}
                  autoHideTimeout={500}
                  autoHeight
                  autoHeightMin={495}
                  autoHeightMax={495}
                >
                  {!catalogsLoading &&
                    catalogs
                      ?.filter(
                        (item) => item.type === FormCatalogTypeEnum.PRICING_INFO
                      )
                      .map((catalog) => (
                        <RadioButtonWithDesc
                          {...register('catalogUrl', { required: true })}
                          data-flow-option
                          id={catalog.id}
                          key={catalog.id}
                          label={catalog.name}
                          value={catalog.fileUrl}
                        />
                      ))}

                  {!catalogsLoading && !catalogs && (
                    <EmptyState
                      marginTop={10}
                      marginBottom={30}
                      title="There are no catalogs"
                    />
                  )}

                  {catalogsLoading && <Loader size={42} />}
                </ScrollbarsCustom>

                <Spacer h="30px" />
              </Wrapper>
            </Wrapper>

            <Wrapper flex justifyEnd>
              <ButtonPrimary
                type="button"
                onClick={handleSubmit((data: CatalogsForm) =>
                  onCatalogSelect(data, close)
                )}
                disabled={!formState.isValid || !catalogs?.length}
              >
                Open
              </ButtonPrimary>
            </Wrapper>
          </Modal>
        )}
      </ModalWrapper>
    </BrowseCatalogsContainer>
  );
};

export default BrowseCatalogs;
